<template>
  <MasterTable
    title="Orsaker"
    :rows="reasons"
    :columns="columns"
    :loading="loading"
    :add="add"
    :update="update"
    :remove="remove"
    :reset-form-model="resetFormModel"
    :form-model="model"
    :export-data="exportData"
    remove-item-text-prop="uuid"
    @update-form-model="(row) => (model = row)"
  >
    <template v-slot:form>
      <div class="row" style="min-width: 600px; height: 380px">
        <div class="col-6 q-pa-md">
          <div class="row">
            <div class="col-12">
              <q-input
                dense
                v-model="model.reason1"
                label="Orsak 1*"
                :rules="[requiredRule]"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <q-input
                dense
                v-model="model.reason2"
                label="Orsak 2*"
                :rules="[requiredRule]"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <q-input
                dense
                v-model="model.reason3"
                label="Orsak 3*"
                :rules="[requiredRule]"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 column">
              <div class="text-overline">Projekt*</div>

              <q-select
                style="display: none"
                :options="[true, false]"
                v-model="someProjectSelected"
                :rules="[booleanTrueRequired]"
              />

              <q-checkbox v-model="model.oresund" label="Öresund" />
              <q-checkbox v-model="model.krosatag" label="Krösatåg" />
              <q-checkbox v-model="model.gotalandstag" label="Götalandståg" />
              <q-slide-transition>
                <div class="text-negative" v-if="!someProjectSelected">
                  <div class="q-pa-sm">
                    <q-icon name="mdi-alert-circle" /> Minst ett projekt måste
                    väljas
                  </div>
                </div>
              </q-slide-transition>
            </div>
          </div>
        </div>
        <div
          class="col-6 q-pa-md"
          style="border-left: 1px solid rgba(0, 0, 0, 0.12)"
        >
          <div class="row">
            <div class="col-12">
              <q-input
                dense
                v-model="model.description"
                label="Beskrivning*"
                :rules="[requiredRule]"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <q-input
                dense
                v-model="model.externalDescription"
                label="Beskrivning extern*"
                :rules="[requiredRule]"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <q-input
                dense
                v-model="model.category"
                label="Katogorisering*"
                :rules="[requiredRule]"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <q-checkbox v-model="model.penalty" label="Vite" />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <q-checkbox
                v-model="model.mandatory_parent_event"
                label="Tvingad länkad händelse"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </MasterTable>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import { useReason } from '@/composable/useReason'
import { Reason } from '@/types/reason'
import {
  required as requiredRule,
  booleanTrueRequired,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'

export default defineComponent({
  name: 'MasterReasons',

  components: {
    MasterTable,
  },

  setup() {
    const {
      loading: loadingStateReasons,
      data: reasons,
      add,
      update,
      remove,
    } = useReason(true)

    function createFormModel(): Reason {
      return {
        uuid: uuidv4(),
        reason1: '',
        reason2: '',
        reason3: '',
        description: '',
        externalDescription: '',
        penalty: false,
        category: '',
        mandatory_parent_event: false,
        oresund: false,
        krosatag: false,
        gotalandstag: false,
      }
    }
    const model = ref<Reason>(createFormModel())
    const someProjectSelected = ref(false)

    function resetFormModel() {
      model.value = createFormModel()
    }

    const columns = [
      {
        name: 'reason1',
        label: 'Orsak 1',
        align: 'left',
        field: 'reason1',
        sortable: true,
      },
      {
        name: 'reason2',
        label: 'Orsak 2',
        align: 'left',
        field: 'reason2',
        sortable: true,
      },
      {
        name: 'reason3',
        label: 'Orsak 3',
        align: 'left',
        field: 'reason3',
        sortable: true,
      },
      {
        name: 'description',
        label: 'Beskrivning',
        align: 'left',
        field: 'description',
        sortable: true,
      },
      {
        name: 'externalDescription',
        label: 'Beskrivning extern',
        align: 'left',
        field: 'externalDescription',
        sortable: true,
      },
      {
        name: 'category',
        label: 'Kategorisering',
        align: 'left',
        field: 'category',
        sortable: true,
      },
      {
        name: 'oresund',
        label: 'Öresund',
        align: 'left',
        field: 'oresund',
        format: (v: boolean) => (v ? 'Ja' : ''),
        sortable: true,
      },
      {
        name: 'krosatag',
        label: 'Krösatåg',
        align: 'left',
        field: 'krosatag',
        format: (v: boolean) => (v ? 'Ja' : ''),
        sortable: true,
      },
      {
        name: 'gotalandstag',
        label: 'Götalandståg',
        align: 'left',
        field: 'gotalandstag',
        format: (v: boolean) => (v ? 'Ja' : ''),
        sortable: true,
      },
      {
        name: 'penalty',
        label: 'Vite',
        align: 'left',
        field: 'penalty',
        format: (v: boolean) => (v ? 'Ja' : ''),
        sortable: true,
      },
      {
        name: 'mandatory_parent_event',
        label: 'Tvingad länkad händelse',
        align: 'left',
        field: 'mandatory_parent_event',
        format: (v: boolean) => (v ? 'Ja' : ''),
        sortable: true,
      },
    ]

    const loading = computed(() => {
      return {
        ...loadingStateReasons.value,
        getAll: loadingStateReasons.value.getAll,
      }
    })

    const exportData = computed(() => {
      return reasons.value.map((reason) => {
        return {
          'Orsak 1': reason.reason1,
          'Orsak 2': reason.reason2,
          'Orsak 3': reason.reason3,
          Beskrivning: reason.description,
          'Extern beskrivning': reason.externalDescription,
          Kategorisering: reason.category,
          Öresund: reason.oresund,
          Krösatåg: reason.krosatag,
          Götalandståg: reason.gotalandstag,
          Vite: reason.penalty,
        }
      })
    })

    watch(
      model,
      () => {
        if (
          model.value.oresund ||
          model.value.krosatag ||
          model.value.gotalandstag
        ) {
          someProjectSelected.value = true
        } else {
          someProjectSelected.value = false
        }
      },
      { deep: true }
    )

    return {
      someProjectSelected,
      columns,
      model,
      requiredRule,
      booleanTrueRequired,
      reasons,
      add,
      remove,
      update,
      loading,
      resetFormModel,
      exportData,
    }
  },
})
</script>
