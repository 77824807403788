import { Ref, ref, ComputedRef, computed } from 'vue'
import { Reason } from '@/types/reason'
import { getReasons } from '@/api/reason/getReasons'
import { deleteReason } from '@/api/reason/deleteReason'
import { createReason } from '@/api/reason/createReason'
import { updateReason } from '@/api/reason/updateReason'
import { useLoading, Loading } from '@/composable/useLoading'

interface UseReason {
  data: Ref<Reason[]>
  fetchAll: () => Promise<Reason[]>
  remove: (uuid: string) => Promise<null>
  add: (body: Reason) => Promise<Reason>
  update: (body: Reason) => Promise<Reason>
  loading: ComputedRef<Loading>
}

export function useReason(initFetchAll?: boolean): UseReason {
  const data = ref<Reason[]>([])
  const { state } = useLoading()

  async function fetchAll() {
    state.getAll = true
    return new Promise<Reason[]>((resolve, reject) => {
      getReasons()
        .then(({ data: reason }) => {
          data.value = reason
          resolve(reason)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function remove(uuid: string) {
    state.delete = true
    return new Promise<null>((resolve, reject) => {
      deleteReason(uuid)
        .then(() => {
          data.value = data.value.filter((item) => item.uuid !== uuid)
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          state.delete = false
        })
    })
  }

  async function add(body: Reason) {
    state.create = true
    return new Promise<Reason>((resolve, reject) => {
      createReason(body)
        .then(({ data: reason }) => {
          data.value.push(reason)
          resolve(reason)
        })
        .catch(reject)
        .finally(() => {
          state.create = false
        })
    })
  }

  async function update(body: Reason) {
    state.update = true
    return new Promise<Reason>((resolve, reject) => {
      updateReason(body)
        .then(({ data: reason }) => {
          data.value = data.value.map((item) => {
            if (item.uuid === reason.uuid) {
              return {
                ...item,
                ...reason,
              }
            }

            return item
          })
          resolve(reason)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    remove,
    add,
    update,
    loading: computed(() => state),
  }
}
