import { render } from "./Reasons.vue?vue&type=template&id=0bd5f688"
import script from "./Reasons.vue?vue&type=script&lang=ts"
export * from "./Reasons.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QSelect,QCheckbox,QSlideTransition,QIcon});
